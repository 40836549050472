@font-face {
  font-family: 'Circe';
  src: local('Circe'), url('./../../assets/fonts/Circe.otf');

}

@font-face {
  font-family: 'CirceBold';
  src: local('CirceBold'), url('./../../assets/fonts/CirceBold.otf');
}

@font-face {
  font-family: 'CirceLight';
  src: local('CirceLight'), url('./../../assets/fonts/CirceLight.otf');
}

@font-face {
  font-family: 'CirceExtraLight';
  src: local('CirceExtraLight'), url('./../../assets/fonts/CirceExtraLight.otf');
}

@font-face {
  font-family: 'CirceThin';
  src: local('CirceThin'), url('./../../assets/fonts/CirceThin.otf');
}

body {
  font-family: Circe, sans-serif!important;
  margin: 0px;
  font-size: 16px;
}

.appstore_btn img {
  background-color: black;
  border-radius: 5px;
  margin-right: 6px;
}

.appgallery_btn img {
  margin-left: 6px;
}

#intro_text, .how_it_works_body, .why_we_item_text, .about_us_intro, #footer, .price_descr, .price > div, .da-slide p, .CirceLight  {
  font-family: CirceLight, sans-serif;
}

#how_it_works {
  background-color: #5a6b72;
}

#how_it_works {
  color: white;
}

a {
  line-height: 24px;
  text-decoration: underline;
}

.a_concierge {
  a, .color, a:hover, .price > div {
    color: #EF3124!important;
  }

  .section_title h1 {
    color: #EF3124;
  }

  #app_download_button {
    background: #EF3124;
  }

  #app_download_button:hover {
    background: #CC2A1F;
  }
}

.o_concierge {
  a, .color, a:hover, .price > div {
    color: #45c8f3!important;
  }

  .section_title h1 {
    color: #45c8f3;
  }

  #app_download_button {
    background: #45c8f3;
  }

  #app_download_button:hover {
    background: #00BBE4;
  }
}

.gpb_concierge {
  a, .color, a:hover, .price > div {
    color: #562737!important;
  }

  .section_title h1 {
    color: #562737;
  }

  #app_download_button {
    background: #562737;
  }
}

.o_lifestyle {
  a, .color, a:hover, .price > div {
    color: #1E1E46!important;
  }

  .section_title h1 {
    color: #1E1E46;
  }

  #app_download_button {
    background: #1E1E46;
  }
}

#bot__how-it-works .container {
  background-color: #f1f1f2;
  padding: 48px;
}

#bot_links .container {
  position: relative;
}

#bot_header {
  position: relative;
  height: 75px;
}

.container .ask_questions {
  font-size: 18px;
  font-family: 'CirceLight';
}

.bot_content {
  font-size: 18px;
  font-family: 'CirceLight';
}

.bot-content ul {
  padding-left: 6px;
}

.section_title h1 {
  font-size: 36px!important;
  margin-top: 24px;
  margin-bottom: 32px;
}

#app_download_button {
  border: none;
  color: white;
  font-size: 24px;
  padding: 12px;
  width: 320px;
  margin-top: 24px;
  font-family: 'CirceLight';
}

@media (max-width: 767px) {
  .container .ask_questions {
    margin: 18px 9px 32px 9px;
  }

  #app_download_button {
    width: 100%;
  }

  #bot__how-it-works .container {
    padding: 24px;
  }

  #how_it_works, #what_we_can, #why_we, #tarifs_wrapper, #about_us {
    padding: 50px 0px;
  }

  .section_title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  #bot_title {
    padding-top: 48px;
  }

  #bot_title .screen_animated {
    position: relative;
  }

  #bot__how-it-works {
    margin-top: 57vw;
  }
}

.a_concierge, .o_concierge, .gpb_concierge, .o_lifestyle {
  margin: auto;
}

@media (min-width: 768px) {
  .a_concierge, .o_concierge, .gpb_concierge, .o_lifestyle {
    width: 700px;
  }

  .container .ask_questions {
    margin: 0 0 32px 0;
  }

  .section_title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .how_it_works_img {
    margin-bottom: 60px;
    min-height: 80px;
  }

  .how_it_works_body,  .slide-text p, .why_we_item_text, .price_descr, .about_us_intro {
    letter-spacing: 1px;
  }

  #bot_title .container {
    position: relative;
    height: inherit;
    padding-top: 64px;
  }

  .container.height {
    height: 600px;
  }
}

@media (min-width: 992px) {
  .a_concierge, .o_concierge, .gpb_concierge, .o_lifestyle {
    width: 900px;
  }

  #bot__how-it-works {
    margin-top: 48px;
    margin-bottom: 50px;
  }

  #bot_title .container {
    display: flex;
    max-height: 90vh;

    .container_block {
      width: 100%;

      &.first {
        display: grid;
        align-self: center;
      }

      &.second {
        width: 50%;
        display: inline-flex;
      }

      .img-responsive {
        max-height: 75%;
        margin: auto;
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1240px) {
  .a_concierge, .o_concierge, .gpb_concierge, .o_lifestyle {
    width: 1200px;
  }
}


@media (max-width: 991px) {
  #bot__how-it-works {
    margin: 24px 0;
  }

  #bot_title .container {
    display: grid;
    align-content: flex-start;
    height: 100%;

    .container_block {
      padding: 0 18px;

      &.second {
        text-align: center;
      }
    }

    .img-responsive {
      height: 100%;
      margin: auto;
      max-height: 65vh;
    }
  }

  #apps_black {
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  #bot__how-it-works {
    margin-top: 48px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  #bot__how-it-works .bot_content {
    margin-bottom: 40px;
  }

  #bot__how-it-works {
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  #bot__how-it-works .animated-img-left {
    position: absolute;
    right: -10%;
  }

  #bot__how-it-works .bot_content {
    margin-bottom: 24px;
  }
}

#app_download_button {
  transition: background 1.2s ease;
}

#app_download_button:hover {
  transition: background 1.2s ease;
}

body{-webkit-backface-visibility:hidden;}
.animated{-webkit-animation-duration:1s;-moz-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;}

@-webkit-keyframes fadeInDown{0%{opacity:0;-webkit-transform:translateY(-20px);}
  100%{opacity:1;-webkit-transform:translateY(0);}
}
@-moz-keyframes fadeInDown{0%{opacity:0;-moz-transform:translateY(-20px);}
  100%{opacity:1;-moz-transform:translateY(0);}
}
@-o-keyframes fadeInDown{0%{opacity:0;-o-transform:translateY(-20px);}
  100%{opacity:1;-o-transform:translateY(0);}
}
@keyframes fadeInDown{0%{opacity:0;transform:translateY(-20px);}
  100%{opacity:1;transform:translateY(0);}
}
.animated.fadeInDown{-webkit-animation-name:fadeInDown;-moz-animation-name:fadeInDown;-o-animation-name:fadeInDown;animation-name:fadeInDown;}

@-webkit-keyframes fadeInLeft{0%{opacity:0;-webkit-transform:translateX(-20px);}
  100%{opacity:1;-webkit-transform:translateX(0);}
}
@-moz-keyframes fadeInLeft{0%{opacity:0;-moz-transform:translateX(-20px);}
  100%{opacity:1;-moz-transform:translateX(0);}
}
@-o-keyframes fadeInLeft{0%{opacity:0;-o-transform:translateX(-20px);}
  100%{opacity:1;-o-transform:translateX(0);}
}
@keyframes fadeInLeft{0%{opacity:0;transform:translateX(-20px);}
  100%{opacity:1;transform:translateX(0);}
}
.animated.fadeInLeft{-webkit-animation-name:fadeInLeft;-moz-animation-name:fadeInLeft;-o-animation-name:fadeInLeft;animation-name:fadeInLeft;}

@-webkit-keyframes fadeInRight{0%{opacity:0;-webkit-transform:translateX(20px);}
  100%{opacity:1;-webkit-transform:translateX(0);}
}
@-moz-keyframes fadeInRight{0%{opacity:0;-moz-transform:translateX(20px);}
  100%{opacity:1;-moz-transform:translateX(0);}
}
@-o-keyframes fadeInRight{0%{opacity:0;-o-transform:translateX(20px);}
  100%{opacity:1;-o-transform:translateX(0);}
}
@keyframes fadeInRight{0%{opacity:0;transform:translateX(20px);}
  100%{opacity:1;transform:translateX(0);}
}
.animated.fadeInRight{-webkit-animation-name:fadeInRight;-moz-animation-name:fadeInRight;-o-animation-name:fadeInRight;animation-name:fadeInRight;}
